import React from "react";
import IsotopeData from "./IsotopeData";
import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';

const IsotopeEdit = React.memo((props) => {
    const { selected, onChangeBlock, block, data } = props;

    return(
        <>
        <p>Filter</p>
        <SidebarPortal selected={selected}>
            <IsotopeData 
             {...props}
             data={data}
             block={block}
             onChangeBlock={onChangeBlock}
            />
        </SidebarPortal>
        </>
    )
})

export default IsotopeEdit;