import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import PropTypes from 'prop-types';
import LightGallery from 'lightgallery/react';
import { Image } from 'semantic-ui-react';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';

const LightGalleryView = ({ data }) => {
  const dispatch = useDispatch();
  const images = useSelector(
    (state) => state?.search?.subrequests?.images?.items,
  );

  React.useEffect(() => {
    dispatch(
      searchContent(
        '/bilder',
        {
          portal_type: 'Image',
          b_size: 1000,
        },
        'images',
      ),
    );
  }, []);

  return (
    <>
      <div className="App">
        {data.title?.length > 0 && (
          <h1>{data.title}</h1>
        )}
        {images?.length > 0 && (
          <ImageList variant="masonry" cols={3} gap={8}>
            <LightGallery plugins={[lgZoom]} mode="lg-fade" licenseKey='7A2B0035-CC15400F-9033398C-0D4C7A80' elementClassNames="lightgallery">
              {images?.map((item, index) => (
                <ImageListItem key={index} className="gallery-item"
                  href={flattenToAppURL(`${item['@id']}/@@images/${item?.image_field}`,)}
                  data-sub-html={item?.description}
                  data-src={flattenToAppURL(`${item['@id']}/@@images/${item?.image_field}`,)}>
                  <Image src={`${item['@id']}/@@images/${item?.image_field}/preview`} className="img-responsive" alt={item?.description} />
                </ImageListItem>
              ))}
            </LightGallery>
          </ImageList>
        )}
      </div>
    </>
  );
};

LightGalleryView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
};

export default LightGalleryView;