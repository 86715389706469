/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { List, Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {

  return (
    <Segment role="contentinfo" padded id="footer">
      <div className="footerInner">
        <div>
          <List className="footerList">
            <div role="listitem" className="item">
              <a className="item">
                <FormattedMessage
                  id="Familie Schenk"
                  defaultMessage="Familie Schenk"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <a className="item">
                <FormattedMessage
                  id='Sahlen 52 B'
                  defaultMessage="Sahlen 52 B"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <a className="item">
                <FormattedMessage
                  id='3538 Röthenbach'
                  defaultMessage="3538 Röthenbach"
                />
              </a>
            </div>
          </List>
        </div>
        
        <div>
          <List className="footerList">
            <div role="listitem" className="item">
              <a className="item" href="mailto:schenk@sahlenweidli.ch">
                <FormattedMessage
                  id='schenk@sahlenweidli.ch'
                  defaultMessage="schenk@sahlenweidli.ch"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <a className="item" href="tel:0344914020">
                <FormattedMessage
                  id='034 491 40 20'
                  defaultMessage="034 491 40 20"
                />
              </a>
            </div>
          </List>
        </div>

        <div>
          <List className="footerList">
            <div role="listitem" className="item">
              <a className="item" href="./datenschutz">
                <FormattedMessage
                  id='privacy'
                  defaultMessage="Datenschutzerklärung"
                />
              </a>
            </div>
            <div role="listitem" className="item">
              <a className="item" href="./impressum">
                <FormattedMessage
                  id='Imprint'
                  defaultMessage="Impressum"
                />
              </a>
            </div>
          </List>
        </div>
      </div>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
