
export const LightGallerySchema = (props) => {
  return {
    title: 'Bilder Galerie',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title'],
      },
    ],

    properties: {
      title: {
        title: 'Titel',
        widget: 'text',
      },
    },
    required: [],
  };
};

export default LightGallerySchema;