const isotopeItemSchema = (props) => {
    const { intl } = props;

    return {
      title: 'Tag',
      addMessage: 'Filter hinzufügen',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['tagname'],
        },
      ],

      properties: {
        tagname: {
          title: 'Name',
          widget: 'text',
          required: true,
        },
      },
      required: [],
    };
};

export const IsotopeSchema = (props) => {
    return {
        title: 'Filter',
        fieldsets: [
            {
                id: 'default',
                title: 'Default',
                fields: [ 'default_tags'],
            },
        ],

        properties: {
            default_tags: {
                widget: 'object_list',
                title: 'Filter Tags',
                schema: isotopeItemSchema,
            },
        },
        required: [],
    };
};

export default IsotopeSchema;